//Window.jsx
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { gsap } from 'gsap';
import './css/Window.css';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

function Window() {
  const [isVisible, setIsVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [userId, setUserId] = useState(
    localStorage.getItem('userId') || uuidv4()
  );
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const inputRef = useRef(null);
  const contentRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const recordingTimeoutRef = useRef(null);

  useEffect(() => {
    if (!localStorage.getItem('userId')) {
      localStorage.setItem('userId', userId);
    }
  }, [userId]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.post('https://api.clever-coin.ru/chat/history', {
          id: userId,
        });

        if (response.data && Array.isArray(response.data.messages)) {
          const loadedMessages = response.data.messages.map((message) => ({
            role: message.role,
            content: message.content,
          }));
          setMessages(loadedMessages.reverse());
        } else {
          console.error('No messages found or incorrect response format.');
          setMessages([]);
        }
      } catch (error) {
        console.error('Error fetching messages from server:', error);
        alert('Failed to load messages from the server. Please try again later.');
      }
    };

    fetchMessages();
  }, [userId]);

  const toggleWindow = useCallback(() => {
    setIsVisible((prevState) => !prevState);
  }, []);

  const handleScroll = useCallback(() => {
    if (buttonRef.current) {
      const top = buttonRef.current.getBoundingClientRect().top;
    }
  }, []);

  const handleResize = useCallback(() => {
    if (window.innerWidth < 500 && isVisible) {
      gsap.to(containerRef.current, {
        y: Math.min(
          0,
          window.innerHeight - 50 - containerRef.current.offsetHeight
        ),
        duration: 0.3,
      });
    } else if (isVisible) {
      gsap.to(containerRef.current, { y: 0, duration: 0.3 });
    } else {
      gsap.to(containerRef.current, { y: '91%', duration: 0.3 });
    }
    handleScroll();
  }, [isVisible, handleScroll]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleResize, handleScroll]);

  useEffect(() => {
    gsap.to(containerRef.current, { y: isVisible ? 0 : '91%', duration: 0.3 });
  }, [isVisible]);

  const vibrate = (duration) => {
    if (navigator.vibrate) {
      navigator.vibrate(duration);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
        audioChunksRef.current = [];

        const formData = new FormData();
        formData.append('file', audioBlob, 'voice.mp3');
        formData.append('id', userId);

        try {
          const response = await axios.post('https://api.clever-coin.ru/chat/audio', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            responseType: 'blob',  // Ожидаем ответ в виде бинарных данных (Blob)
          });

          if (response.status === 200) {
            // Создаем URL для аудиофайла и воспроизводим его
            const audioUrl = URL.createObjectURL(response.data);
            const audio = new Audio(audioUrl);
            audio.play();

            // Добавляем сообщение в историю
            const botResponse = {
              role: 'assistant',
              content: 'Аудиоответ воспроизведен', // Условный текстовый контент для отображения в чате
            };
            setMessages((prevMessages) => [botResponse, ...prevMessages]);

          } else {
            console.error('No response from server or incorrect response format.');
          }
        } catch (error) {
          console.error('Error sending audio to server:', error);
          alert(`Failed to send audio to the server. Please try again later. Error: ${error.message}`);
        }
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      vibrate(80); // Включаем короткую вибрацию при начале записи
    } catch (error) {
      console.error('Error accessing microphone:', error);
      alert('Failed to access microphone. Please check your settings and try again.');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
    setIsRecording(false);
    vibrate(80); // Включаем короткую вибрацию при остановке записи
  };

  const handleMouseDown = () => {
    recordingTimeoutRef.current = setTimeout(() => {
      startRecording();
      recordingTimeoutRef.current = null;
    }, 100);
  };

  const handleMouseUp = () => {
    if (recordingTimeoutRef.current) {
      clearTimeout(recordingTimeoutRef.current);
      recordingTimeoutRef.current = null;
    } else {
      stopRecording();
    }
  };

  const handleSendMessage = async () => {
    if (inputText.trim()) {
      const newMessage = { role: 'user', content: inputText };
      setMessages((prevMessages) => [newMessage, ...prevMessages]);
      setInputText('');

      if (inputRef.current) {
        inputRef.current.focus();
      }

      if (contentRef.current) {
        setTimeout(() => {
          contentRef.current.scrollTop = 0;
        }, 0);
      }

      try {
        const response = await axios.post('https://api.clever-coin.ru/chat', {
          id: userId,
          message: inputText,
        });

        if (response.data && response.data.response) {
          const botResponse = {
            role: 'assistant',
            content: response.data.response,
          };
          setMessages((prevMessages) => [botResponse, ...prevMessages]);
        } else {
          console.error('No response from server or incorrect response format.');
        }
      } catch (error) {
        console.error('Error sending message to server:', error);
        alert(`Failed to send message to the server. Please try again later. Error: ${error.message}`);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="container" ref={containerRef}>
      <div className="window">
        <div className="window-content" ref={contentRef}>
          {messages.length > 0 ? (
            messages.map((message, index) => (
              <div key={index} className={`chat-message ${message.role}`}>
                {message.content}
              </div>
            ))
          ) : (
            <div className="no-messages">Нет сообщений</div>
          )}
        </div>
        <div className="input-container">
          <textarea
            className="window-input"
            rows="3"
            placeholder="Введите сообщение..."
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={handleKeyDown}
            ref={inputRef}
          ></textarea>
          {inputText.trim() ? (
            <button className="send-icon-button send-button" onClick={handleSendMessage}></button>
          ) : (
            <button
              className="send-icon-button mic-button"
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onTouchStart={handleMouseDown}
              onTouchEnd={handleMouseUp}
            ></button>
          )}
        </div>
      </div>
      <button className="toggle-button" ref={buttonRef} onClick={toggleWindow}>
        {isVisible ? 'Close ChatGPT' : 'Open ChatGPT'}
      </button>
    </div>
  );
}

export default Window;
