import React, { useEffect } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

function About() {
  
  return   (<div className='about'>
  <h1> About</h1>
     <p>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam aliquet,
  purus vitae eleifend tristique, lorem magna volutpat orci, et vehicula
  erat erat nec elit. Aenean posuere nunc ac cursus facilisis. Aenean vel
  porta turpis, ut iaculis justo.
</p>
  </div>)

}

export default About
