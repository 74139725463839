//Headler.jsx
import { NavLink } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './css/Header.css';
import { ReactComponent as MenuIcon } from './assets/menuIcon.svg';
import { ReactComponent as CloseIcon } from './assets/closeIcon.svg';
import gsap from 'gsap';

const Header = () => {
  const [isOpen, setOpen] = useState(false);

  const handleToggleMenu = () => {
    setOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      gsap.fromTo('.menu-icon', { opacity: 1, rotation: 0 }, { opacity: 0, rotation: 90, duration: 0.1 });
      gsap.fromTo('.close-icon', { opacity: 0, rotation: -90 }, { opacity: 1, rotation: 0, duration: 0.3 });
      gsap.to('.header_nav', { autoAlpha: 1, duration: 0.5 });
    } else {
      gsap.fromTo('.menu-icon', { opacity: 0, rotation: 90 }, { opacity: 1, rotation: 0, duration: 0.3 });
      gsap.fromTo('.close-icon', { opacity: 1, rotation: 0 }, { opacity: 0, rotation: -90, duration: 0.1 });
      gsap.to('.header_nav', { autoAlpha: 0, duration: 0.5 });
    }
  }, [isOpen]);

  return (
    <header className="header">
      <button className="header_menu-button" onClick={handleToggleMenu}>
        <MenuIcon className="menu-icon" />
        <CloseIcon className="close-icon" />
      </button>
      <nav className={`header_nav ${isOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <NavLink to="/" end>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/about">About</NavLink>
          </li>
          <li>
            <NavLink to="/contacts">Contacts</NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
